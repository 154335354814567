<template>
  <div class="star">
    <div
      class="star-content"
      ref="container"
      @contextmenu.prevent="toHideContext"
    >
      <h2 class="star-title font18 black-font">搜索结果</h2>
      <div class="star-header-top">
        <!-- 总览, 全选 -->
        <a-radio :checked="checkAll" @click="onCheckAll">
          共{{ listData.length }}项
        </a-radio>
        <!-- 列表排序条件 -->
        <div class="star-header-top-right">
          <!--@handleClick点击menu项 -->
          <!-- 排序规则 -->
          <MenuSimple
            :list="topFilterList"
            :checkedList="[...sortIndicatorList, ...sortRuleList]"
            @handleClick="handleClick"
          />
          <!-- 切换视图 -->
          <!-- <MenuSimple :list="conversionModeList" @handleClick="handleClick" /> -->
          <!-- v-if="functionalCheck('DETAIL_FILE')" -->
          <div class="fileDetails click" @click="handleDetail">
            <h-icon
              className="star-header-top-icon"
              iconName="fileDetails"
            ></h-icon>
            <span class="font12 gary-font"> 文件信息 </span>
          </div>
        </div>
      </div>

      <!-- 文件列表 -->
      <div
        class="star-content-fileContainer"
        ref="starContent"
        @scroll="onFileScroll"
      >
        <!-- 展示table形式 -->
        <!-- @checkListItem 选中文件改变时触发, 返回所有文件id -->
        <FileList
          v-if="showTable"
          :listData="listData"
          :checkIdList="checkIdList"
          :pagination="false"
          @checkListItem="(arr) => flSetCheckIdList(arr)"
          @checkItem="toCheckItem"
          @dbCheckItem="toDblclickItem"
          @contextMenu="(e, el) => onContextMenu(e, 'fileContextContent', el)"
        />
        <!-- 展示平铺文件夹形式 -->
        <!-- @checkItem单击文件 / @dbCheckItem双击文件 / @menuClick点击文件菜单 -->
        <TileList
          v-else
          :checkIdList="checkIdList"
          :listData="listData"
          @checkItem="toCheckItem"
          @dbCheckItem="toDblclickItem"
          @menuClick="handleClick"
          @sendMousedown="handleMousedown"
          @contextMenu="(e, el) => onContextMenu(e, 'fileContextContent', el)"
        />
      </div>

      <!-- 文件右键菜单 -->
      <!-- @handleClick点击右键菜单 -->
      <div
        class="star-content-contextmenu star-content-contextmenu-simple"
        ref="fileContextContent"
        @contextmenu.stop.prevent="() => {}"
      >
        <MydocMenu
          :type="checkIdList.length > 1 ? 'normal' : 'full'"
          ref="fileMenu"
          @handleClick="handleClick"
        />
      </div>

      <!-- 弹窗 -->
      <!-- visible控制弹窗展示 / popupType弹窗类型 / options其他参数 / @close弹窗关闭事件 -->
      <Popup
        :visible="visible"
        :popupType="popupType"
        :options="popupOptions"
        @close="onPopupClose"
      />

      <PreviewImg
        :imgs="previewImgList"
        :showId="previewId"
        @previewHide="previewHide"
      />

      <!-- 协作 -->
      <CooperationModal
        v-if="cooperationVisible"
        :visible="cooperationVisible"
        :loading="cooperationLoading"
        :type="cooperationType"
        :file="checkFile"
        @close="cooperationVisible = false"
      />

      <!-- 分享 -->
      <ShareModal
        :visible="shareVisible"
        :loading="shareLoading"
        :type="shareType"
        :shareKey="shareKey"
        @close="shareVisible = false"
      />
    </div>
    <div class="file-detail" v-if="detailShow">
      <FileDetails @sendClose="detailShow = false" :file="showFileDetail" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import MydocMenu from "@/views/mydoc/menu.vue";
import MenuSimple from "@/views/mydoc/menuSimple.vue";
import FileList from "@/views/recycle/fileList.vue";
import TileList from "@/views/mydoc/tileList.vue";
import PreviewImg from "@/components/previewImg/index.vue";
import CooperationModal from "@/views/mydoc/CooperationModal.vue";
import ShareModal from "@/views/mydoc/ShareModal.vue";
import FileDetails from "@/components/FileDetails/index.vue";
import { Radio } from "ant-design-vue";
import Popup from "@/views/popup/index.vue";
// 内容较多, 数据暂存混入文件, 此文件只写模板
import Mixins from "@/views/mydoc/mixin/index";
import { imgEx, onlineEx } from "@/common/utils/fileEx";
// @ts-ignore
import { publicFormatSize, publicFileView } from "@/utils/index";
export default Vue.extend({
  name: "SearchResult",
  mixins: [Mixins],
  components: {
    [Radio.name]: Radio,
    MydocMenu,
    MenuSimple,
    FileList,
    TileList,
    Popup,
    PreviewImg,
    CooperationModal,
    ShareModal,
    FileDetails,
  },
  props: {},
  data() {
    return {
      checkedFileItemList: [],
    };
  },
  watch: {},
  computed: {
    teamList() {
      return this.$store.state.team.teamList;
    },
  },
  created() {},
  mounted() {},
  methods: {
    fetchData() {
      let params = {
        keyWord: this.$route.query.fileName,
        fileType: this.$route.query.fileType,
        source: this.$route.query.source,
        companyId: this.$store.state.user.companyId,
        // moduleType: 'doc',
        isPage: 0,
      };
      return this.$apis.searchUserFile(params).then((res) => {
        let searchList = res?.data?.list;
        searchList.forEach((item) => {
          if (item.teamId && item.teamId.length) {
            // 使用find方法来查找匹配的teamName
            const matchedTeam = this.teamList.find(
              (ele) => ele.id === item.teamId
            );
            if (matchedTeam) {
              // 如果找到了匹配的项，则更新item的teamName
              item.teamName = matchedTeam.teamName;
              item.userFilePath = `${item.teamName}${item.userFilePath}`;
            }
          } else {
            item.userFilePath = `个人文档${item.userFilePath}`;
          }
        });

        this.setData({ list: searchList });
        return Promise.resolve();
      });
    },
    // 双击文件/文件夹
    toDblclickItem(el) {
      console.log("搜索结果的el", el);

      // 如果是文件夹
      if (el.userFileType === "folder") {
        let query = {
          id: el.id,
          teamId: el.teamId,
        };
        this.$router.push({
          path:
            el.moduleType === "doc"
              ? "/star/mydoc"
              : "/star/companyTeam/document",
          query,
        });
        // } else if (onlineEx.includes(el.extensionName)) {
      } else {
        let temp = {
          ...el,
          actionFileSize: publicFormatSize(el.userFileSize),
          actionFileid: el.id,
          actionFileFullName: `${el.userFileName}.${el.extensionName}`,
        };
        publicFileView(temp);
        // const user = this.$store.state.user;
        // if (user.functionCodes.includes("VIEW_OFFICE_ONLINE")) {
        //   let query = {
        //     userFileId: el.id,
        //     moduleType: this.moduleType,
        //     fileExtension: el.extensionName,
        //     fileName: el.userFileName,
        //     ot: "view",
        //   };
        //   if (this.moduleType === "team")
        //     query.teamId = this.$route.query.teamId;
        //   let routeData = this.$router.resolve({
        //     name: "VIEW_OFFICE_ONLINE",
        //     query,
        //   });
        //   window.open(routeData.href, "_blank"); // 新窗口打开
        // } else {
        //   this.$message.warning("在线文档功能未开启,请联系管理员");
        // }
      }

      // else if (imgEx.includes(el.extensionName)) {
      //   console.log(el.fileId, this.previewImgList);
      //   this.previewId = el.fileId;
      // } else {
      //   // 非在线文件, 直接下载
      //   if (this.$store.state.user.functionCodes.includes("DOWNLOAD_FILE")) {
      //     this.downloadFile(el);
      //   } else {
      //     this.$message.warning("下载功能未开启,请联系管理员");
      //   }
      // }
    },
  },
});
</script>

<style scoped lang="less">
@import url("/src/views/mydoc/index.less");

.star {
  padding: 30px 40px;

  .star-title {
    margin-bottom: 20px;
  }
}
</style>
